// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDbex4gNkhEJWA5ifzqEBB8WGArvrrrDtk",
  authDomain: "personalsite-6cb83.firebaseapp.com",
  projectId: "personalsite-6cb83",
  storageBucket: "personalsite-6cb83.appspot.com",
  messagingSenderId: "645562204190",
  appId: "1:645562204190:web:32b6f98832ef63e0276e0f",
  measurementId: "G-7DV5GBJERY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app, analytics, firebaseConfig };




