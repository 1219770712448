<template>
  <div :class="{ home: true, 'dark-mode': darkMode }">
    <HomeAvatar />
    <IntroductionParagraph />
    <!-- <MetaData />-->

    <!-- Toggle button 
    <label class="toggle-switch">
      <input type="checkbox" v-model="darkMode" @change="toggleDarkMode" />
      <span class="slider round"></span>
    </label>-->
  </div>
</template>

<script>
// @ is an alias to /src
import IntroductionParagraph from "@/components/Home/IntroductionParagraph.vue";
import HomeAvatar from "@/components/Home/HomeAvatar.vue";
// import MetaData from "@/components/Home/MetaData.vue";
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase/firebase.js";
import { useHead } from "@vueuse/head";

export default {
  name: "HomeView",
  data() {
    return {
      darkMode: false, // Initial mode is light
    };
  },
  created() {
    logEvent(analytics, "home_screen_view", {
      screen_name: this.$options.name,
      screen_class: this.$options.name,
    });
  },
  components: {
    IntroductionParagraph,
    HomeAvatar,
    // MetaData
  },
  setup() {
    useHead({
      title: "Deandre Mylove | Personal Site",
      meta: [
        {
          name: "description",
          content:
            "Deandre Mylove is a passionate software engineer based in Reno, NV who specializes in building and designing exceptional websites and applications.",
        },
      ],
    });
  },
  methods: {
    toggleDarkMode() {
      // You can perform additional actions when the mode is toggled
      // For example, save the current mode to local storage for persistence
    },
  },
  watch: {
    darkMode(newMode) {
      document.documentElement.classList.toggle("dark-mode", newMode);
    },
  },
};
</script>

<style scoped>
/* Shared styles for both modes */
.home {
  grid-template-columns: auto auto;
  display: flex;
  transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
  .home {
    grid-template-columns: auto;
    display: flex;
    flex-direction: column;
  }
}

/* Light mode styles */
.home {
  background-color: #457b9d;
  color: #333333;
}

/* Dark mode styles */
.dark-mode .home {
  background-color: #333333;
  color: #000000;
}

/* Toggle switch styles */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
</style>
