<template>
  <div class="about-me-section">
    <h1><b>Who is Dre?</b></h1>
    <div class="intro-text">
      <p>
        Hi there! I'm Dre Mylove, a recent graduate from the University of
        Nevada Reno with a Bachelor of Science in Computer Science and
        Engineering and a minor in Math. My passion lies in building innovative
        solutions that tackle real-world problems, and I believe technology has
        the power to empower and connect people in extraordinary ways.
      </p>
      <br>
      <p>
        Throughout my journey, I've honed my skills in software development, web
        development, data analysis, and machine learning. At Tesla, I gained
        valuable experience in production and testing, collaborating with
        engineers to identify and resolve operational challenges. During my
        internship at Hamilton Company, I dove into the world of business
        intelligence, creating interactive Power BI reports and optimizing
        database management systems.
      </p>
      <br>
      
      <p>
        I'm always eager to learn, collaborate, and create. If you're interested
        in connecting, exploring my projects, or sharing ideas, feel free to
       reach out!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroductionParagraph",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.about-me-section {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  max-width: 62%;
  /* margin: auto; */
  border: 3px solid #000000;
  margin-top: 3%;
  margin-right: 2%;
  height: 70%;
}

p {
  margin: 0.2em;
  font-size: 1em;
  font-size: calc(15.5px + 0.25vw);
  line-height: 2;
  text-align: left;
  display: flex;
}

h1 {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  
  font-size: 40px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .about-me-section {
    max-width: 90%;
    margin-inline: auto;
    margin-bottom: 4%;
  }

  p {
    font-size: 16px;
  }

  h1 {
    font-size: 20px;
  }
}
</style>
