<template>
  <div class="resume">
    <section>
      <a class="button-style" @click.prevent="downloadResume"
        >Download Resume </a
      ><span class="file-size">64 KB</span>
    </section>
    <hr />
    <section>
      <h2>Experience</h2>
      <div v-for="job in resume.jobs" :key="job.company" class="job">
        <h3>{{ job.company }}</h3>
        <p class="position">{{ job.position }}</p>
        <p class="date">{{ job.date }}</p>
        <ul class="description">
          <li v-for="(item, index) in job.description" :key="index">
            <p>{{ item }}</p>
          </li>
        </ul>
      </div>
    </section>

    <hr />
    <section>
      <h2>Skills</h2>
      <ul class="skills">
        <li v-for="skill in resume.skills" :key="skill.name">
          <a :href="skill.url" :aria-label="skill.label">{{ skill.name }}</a>
        </li>
      </ul>
    </section>

    <hr />
    <section>
      <h2>Education</h2>
      <div>
        <h3>
          {{ resume.education.degree }}
        </h3>
        
        <p>{{ resume.education.school }}</p>
        <p class="minor">Minor: {{ resume.education.minor }}</p>
        <p class="date">{{ resume.education.date }}</p>
      </div>
    </section>
  </div>
</template>

<script>
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase/firebase.js";
export default {
  name: "ResumePage",

  data() {
    return {
      resume: {
        name: "Deandre Mylove",
        address: "530 E. Patriot Blvd., Reno, NV 89511",
        phone: "(916) 270-9646",
        email: "Dre@DreMylove.com",
        skills: [
          {
            name: "Vue.js",
            url: "https://vuejs.org/",
            label: ":ink to Vue.js documentatioin.",
          },
          {
            name: "JavaScript",
            url: "https://www.javascript.com/",
            label: "Link to JavaScript documentation.",
          },
          {
            name: "Python",
            url: "https://www.python.org/",
            label: "Link to Python documentation.",
          },
          {
            name: "NoSql(FireBase)",
            url: "https://firebase.google.com/",
            label: "Link to Firebase documentation.",
          },
          {
            name: "SQL",
            url: "https://www.mysql.com/",
            label: "Link to MySQL documentaion.",
          },
          {
            name: "Web Development",
            url: "https://developer.mozilla.org/en-US/docs/Learn",
            label: "Link to Mozilla Web Development documentation.",
          },
          {
            name: "HTML & CSS",
            url: "https://www.w3schools.com/html/",
            label: "Link to documentation about HTML and CSS.",
          },
          {
            name: "Resilliance",
            url: "https://www.merriam-webster.com/dictionary/resilience",
            label: "Link to definition of resilliance.",
          },
          {
            name: "Teamwork",
            url: "https://www.merriam-webster.com/dictionary/teamwork",
            label: "Link to definition of teamwork.",
          },
        ],
        summary:
          "Passionate Full Stack Web Developer with a Bachelor’s degree in Computer Science and Engineering and a minor in Mathematics. Demonstrates proficiency in SQL and NoSQL Firebase databases, adept at developing dynamic web applications using leading-edge technologies such as Vue.js, SQL, JavaScript, Python, HTML & CSS. Experienced in collaborative projects with cross-functional teams and well-versed in problem-solving. Aspiring to utilize technical skills and contribute to innovative web development projects in a challenging role.",
        education: {
          degree: "Computer Science and Engineering",
          minor: "Mathematics",
          school: "University of Nevada, Reno",
          date: "May 2023",
          url: "https://catalog.unr.edu/preview_program.php?catoid=52&poid=215363&returnto=106381",
        },
        jobs: [
          {
            company: "Tesla",
            date: "December 2022 - Present",
            position: "Production Associate",
            description: [
              "Collaborated with the engineering team to address operational design issues in real-time.",
              "Helped develop manufacturing instructions for portions of the assembly line.",
            ],
          },
          {
            company: "Hamilton Company",
            date: "August 2022 - November 2022",
            position: "Business Intelligence Intern",
            description: [
              "Developed PowerApps to optimize the management of database tables.",
              "Generated PowerBi reports, providing valuable insights into crucial business metrics.",
              "Ownership of UI design for all PowerApps I developed. ",
            ],
          },
          {
            company: "Tesla",
            date: " November 2017 - November 2019",
            position: "Production Associate",
            description: [
              "Assumed multi-station responsibility in a resource-constrained setting over several months, demonstrating adaptability and ensuring seamless operations during staffing shortages.",
            ],
          },
        ],
      },
    };
  },
  methods: {
    downloadResume() {
      logEvent(analytics, "resume_download", {
        content_type: "resume",
        item_id: "resume",
      });
      window.open("/DownloadableResume.pdf", "_blank");
    },
  },
};
</script>

<style scoped>
.resume {
  margin: auto;
  margin-top: 1%;
  margin-bottom: 1%;
  font-family: "Arial", sans-serif;
  border: 3px solid #000000;
  min-height: 70%;
  max-width: 50%;
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  background-color: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

@media (max-width: 768px) {
  .resume {
    max-width: 90%;
  }

  .button-style {
    font-size: em;
    font-size: calc(16px + 0.25vw);
  }

  .skills {
    flex-direction: column;
  }
}

p {
  margin: 0.2em;
  font-size: 1em;
  font-size: calc(16px + 0.25vw);
  line-height: 2;
  text-align: left;
}

h2 {
  margin: 0.2em;
  font-size: 2em;
  font-size: calc(1.5em + 0.25vw);
}

h3 {
  font-size: 1.5em;
  font-size: calc(1.25em + 0.25vw);
  text-align: left;
  font-weight: bold;
  margin: auto;
  margin-top: 1em;
}

.contact {
  margin: 0.2em;
  font-size: 1em;
}

hr {
  border: none;
  border-top: 1px solid lightgray;
}

section {
  margin: 1em 0;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  /* padding: 0; */
  justify-content: space-evenly;
}

.skills li {
  min-width: 31%;
  margin: 0.5em;

  text-align: center;

  padding: 0.5em;
}

button {
  background-color: rgb(0, 0, 0);
  color: white;
  border: none;
  border-radius: 0.5em;
  padding: 0.5em;
  font-size: 1em;
  font-size: calc(16px + 0.25vw);
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  height: 100%;
}
.skills a {
  /* color: white; */
  /* text-decoration: none; */
  font-weight: bold;
  cursor: pointer;
}

.skills a:hover {
  color: #5c6b73;
}

@media (max-width: 1200px) {
  .skills {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .skills li {
    width: calc(50% - 1em);
  }
}

.button-style {
  display: inline-block;
  padding: 0.5em 1em;
  background-color: #253237;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  margin-left: 50px;
}

.button-style:hover {
  background-color: #5c6b73;
}
.degree {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.job:not(:last-child) {
  margin-bottom: 1em;
}

.file-size {
  margin-left: 10px;
  font-size: 0.8em;
}
</style>
