<template>
  <div class="containerbg">
    <h1>Contact Me</h1>
    <form id="contact-form" @submit.prevent="sendEmail">
      <div class="form-group">
        <label for="name">Name</label>
        <input
          type="text"
          v-model="name"
          tabindex="5"
          class="form-control"
          placeholder="Enter name"
          required
        />
      </div>

      <div class="form-group">
        <label for="exampleInputEmail1">Email address</label>
        <input
          type="email"
          tabindex="6"
          v-model="email"
          class="form-control"
          placeholder="Enter email"
          required
        />
      </div>

      <div class="form-group">
        <label for="message">Message</label>
        <textarea
          class="form-control"
          tabindex="7"
          v-model="message"
          rows="3"
          required
        ></textarea>
      </div>

      <button type="submit" class="btn btn-primary" tabindex="8">Submit</button>
    </form>
    <div v-if="success" class="alert alert-success" role="alert">
      Your message has been sent!
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      There was an error sending your message.
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase/firebase.js";

export default {
  name: "ContactForm",

  data() {
    return {
      name: "",
      email: "",
      message: "",
      success: false,
      error: false,
    };
  },

  methods: {
    sendEmail() {
      let data = {
        from_name: this.name,
        from_email: this.email,
        message: this.message,


      };

      emailjs
        .send(
          "service_w3367fi",
          "template_53t2nas",
          data,
          "Q-P2KyXts4l1UcQZs"
        )
        .then(
          (result) => {
            console.log(result.text);
            this.success = true;
            this.error = false;

            logEvent(analytics, "contact_form_submit", {
              screen_name: this.$options.name,
              screen_class: this.$options.name,
            });
          },
          (error) => {
            console.log(error.text);
            this.success = false;
            this.error = true;

            logEvent(analytics, "contact_form_error", {
              screen_name: this.$options.name,
              screen_class: this.$options.name,
            });
          }
        );
    },
  },
};
</script>

<style scoped>
.containerbg {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  max-width: 40%;
  border: 3px solid #000000;
  margin: 0 auto;
    margin-top: 4%;
}

p {
  margin: 0.2em;
  font-size: 1em;
  font-size: calc(16px + 0.25vw);
  line-height: 2;
  text-align: left;
  display: flex;
  justify-content: center;
}

h1 {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .containerbg {
    max-width: 90%;
    margin-inline: auto;
    margin: 0 auto;
    margin-bottom: 4%;
    margin-top: 4%;
  }
}

button {
  margin-top: 2%;
  
}


</style>
