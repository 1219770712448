<template>
    <div class="resume">
        <ResumePage />
    </div>

</template>

<script>
import ResumePage from "@/components/ResumeComponents/resume.vue"; 
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase/firebase.js";

export default {
    name: 'ResumeView',
    created() {
        logEvent(analytics, "resume_screen_view", {
            screen_name: this.$options.name,
            screen_class: this.$options.name,
        });
    },

    components: {
        ResumePage,
    },
}

</script>

<style scoped>

</style>