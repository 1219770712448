<template>
  <div class="about">
    <h1>Work in progress</h1>
    <h3>Will be an overview of Wolf Campus</h3>
  </div>
</template>

<script>
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase/firebase.js";

export default {
  name: "WolfcampusView",
  created() {
    logEvent(analytics, "wolfcampus_screen_view", {
      screen_name: this.$options.name,
      screen_class: this.$options.name,
    });
  },
};
</script>

<style scoped>
h1,
h3 {
  color: white;
}
</style>
