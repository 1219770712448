<template>
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container-fluid">
      <a class="navbar-brand"  tabindex="1" href="/">Dre Mylove</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        :class="{ collapsed: !isNavOpen }"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleNav"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        :class="['collapse', 'navbar-collapse', isNavOpen ? 'show' : '']"
        id="navbarNavDropdown"
      >
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <router-link class="nav-link" tabindex="2" to="/" @click="closeNav"
              >Home</router-link
            >
          </li>
          
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              tabindex="3"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="toggleDropdown"
            >
              Projects
            </a>
            <ul class="dropdown-menu">
             
              <li>
                <a
                  class="dropdown-item"
                  href="https://wolfcampus.app"
                  target="_blank"
                  @click="closeNav"
                  >Wolf Campus</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="https://frienddiagram.app"
                  target="_blank"
                  @click="closeNav"
                  >Friend Venn</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" tabindex="4" to="/contact" @click="closeNav"
              >Contact</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",

  data() {
    return {
      isNavOpen: false,
      isDropdownOpen: false,
    };
  },

  methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },

    closeNav() {
      this.isNavOpen = false;
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
  },
};
</script>

<style scoped>
nav {
  background: #457b9d;
}

.navbar-brand {
  font-size: 1.5em;
  font-size: calc(1.25em + 0.25vw);
  font-weight: bold;
  color: #ffffff;
}

.nav-link {
  font-size: 1.5em;
  font-size: calc(1.25em + 0.25vw);
  color: #ffffff;
  margin-right: 1em; /* You can adjust this value to increase or decrease the spacing */
}

ul li a{
  transition: color .4s ease-in-out, font-weight .4s ease-in-out;
}

.router-link-active {
  color: #a8dadc;
  /* bold link */
  font-weight: bold;
}

.nav-link:hover {
  color: #a8dadc;
  /* bold link */
  font-weight: bold;
}

.navbar-brand:hover {
  color: #a8dadc;
  /* bold link */
  font-weight: bold;
}

.dropdown-item {
  font-size: 1.5em;
  font-size: calc(1.25em + 0.25vw);
  padding: auto;
  margin: auto;
  font-weight: bold;
  color: #ffffff;
  /* background: rgb(252, 246, 189); */
  background: #457b9d;
}

.dropdown-item:hover {
  color: #a8dadc;
  background: #457b9d;
  /* bold link */
  /* font-weight: bold; */
  
}

.dropdown-menu {
  /* background: rgb(252, 246, 189); */
  background: #457b9d;
}

.navbar-toggler {
  color: #ffffff;
  background-color: #1d3557;
}
</style>
