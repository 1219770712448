import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ResumeView from "../views/ResumeView.vue";
import ThisSiteView from "../views/ThisSiteView.vue";
import ContactView from "../views/ContactView.vue";
import RemembrView from "../views/RemembrView.vue";
import WolfcampusView from "../views/WolfcampusView.vue";

const routes = [
  {
    path: "/resume",
    name: "home",
    component: ResumeView,
  },
  {
    path: "/",
    name: "resume",
    component: HomeView,
  },

  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/thissite",
    name: "thissite",
    component: ThisSiteView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/remembr",
    name: "remembr",
    component: RemembrView,
  },
  {
    path: "/wolfcampus",
    name: "wolfcampus",
    component: WolfcampusView,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


export default router;
