import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { createHead } from "unhead";

const pinia = createPinia();
const head = createHead();

createApp(App).use(pinia).use(router).use(head).mount("#app");
