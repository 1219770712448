<template>
  <div class="text-center">
    <img
      src="@/assets/Images/Hello.png"
      class="rounded-circle mb-3"
      alt="Avatar"
    />

    <section>
      <button type="button" class="btn download-resume" tabindex="5"  @click="downloadResume">
        <i class="fa fa-download"></i>
        Resume <span class="file-size">(48 KB)</span>
      </button>
    </section>

    <div class="social-media-links">
      <a href="https://www.linkedin.com/in/deandremylove/" tabindex="6" target="_blank">
        <i class="fa fa-linkedin-square social-link" ></i>
      </a>


      <a href="https://github.com/Dre-Mylove" tabindex="7" target="_blank">
        <i class="fa fa-github-square social-link" ></i>
      </a>
    </div>
  </div>
</template>

<script>
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase/firebase.js";

export default {
  name: "HomeAvatar",

  data() {
    return {};
  },

  methods: {
    downloadResume() {
      logEvent(analytics, "resume_download", {
        content_type: "resume",
        item_id: "resume",
      });

      // Creating an anchor element
      const a = document.createElement("a");

      // Setting the download attribute and file name
      a.download = "DeandreMyloveResume.pdf";

      // Creating a URL for the file and setting it as the href attribute
      a.href = "/DownloadableResume.pdf";

      // Appending the anchor element to the document body
      document.body.appendChild(a);

      // Programmatically triggering a click event on the anchor element
      a.click();

      // Removing the anchor element from the document body
      document.body.removeChild(a);
    },
  },
};
</script>
<style scoped>
.text-center{
  margin-top: 3%;

}

img {
  /* style like an avatar image */
  max-width: 60%;
  border: 3px solid #000000;
  border-radius: 50%; /* makes the image circular */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  margin-top: 1%;
  margin-right: 3%;
}


.social-media-links {
  margin-top: 2%;
}

.social-link {
  margin: 10px;
  font-size:36px; 
  color:#a8dadc; 
  padding-right: 20px;
  margin: 0 -.25rem;
  margin-inline: center;
  transition: color .4s ease-in-out;
  
}

.social-link:hover {
  color: black;
 
}


.download-resume {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #457b9d;
  background-color: #a8dadc;
  border: none;
  padding: 10px 20px;
  width: 220px;
  height: 50px;
  outline: none;
  position: relative;
  z-index: 0;
  margin: 10px;
  
}
.download-resume:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
  color: black;
}

.download-resume:active {
  color: #000;
}

.download-resume:active:after {
  background: transparent;
}

.download-resume:hover:before {
  opacity: 1;
}

.download-resume:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #a8dadc;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

h5 {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5em;
  font-weight: normal;
  text-align: center;
  margin: 5% auto;
  color: #000;
}

@media (max-width: 768px) {
  img {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 4%;
  }

  .download-resume {
    margin-bottom: 20px;
  }

  .social-link {
    margin-bottom: 20px;
  }
}
</style>
