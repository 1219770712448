<template>
  <nav-bar />
  <router-view />
</template>

<script>
import NavBar from "@/components/NavBar/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: #457b9d;

}



html {
  scroll-behavior: smooth;
  background: rgb(252, 246, 189);
  background: #457b9d;
}

nav a {
  font-weight: bold;
  color: #e4c1f9;
}
</style>
