<template>
  <div class="about">
    <ContactForm />
  </div>
</template>

<script>
import { logEvent } from "firebase/analytics";
import { analytics } from "@/firebase/firebase.js";

import ContactForm from "@/components/ContactForm/ContactForm.vue";

export default {
  name: "ContactView",
  components: {
    ContactForm,
  },
  created() {
    logEvent(analytics, "contact_screen_view", {
      screen_name: this.$options.name,
      screen_class: this.$options.name,
    });
  },
};
</script>

<style scoped>
h1,
h3 {
  color: white;
}
</style>
